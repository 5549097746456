import { Button, ViewContext, Icon } from 'components/lib'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { useContext } from 'react'
import 'jspdf-autotable'

const DownloadButton = ({ closeButtonStyle, historyOpen, isStreaming, handleDownload }) => {
  const downloadHint = isStreaming ? 'Streaming...' : 'Download Chat PDF'

  return (
    <Tippy content={downloadHint} arrow={roundArrow} animation="shift-away" inertia={true}>
      <div>
        <Button
          customIcon={<Icon image="download" size={20} className="mx-auto" />}
          color="dark"
          size={20}
          className={`${closeButtonStyle} ${historyOpen ? '!shadow-none ' : ''} ${isStreaming ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isStreaming}
          action={handleDownload}
        />
      </div>
    </Tippy>
  )
}

class PDFGenerator {
  constructor(abortSignal) {
    this.pdf = new jsPDF('p', 'mm', 'a4')
    this.pdfWidth = this.pdf.internal.pageSize.getWidth()
    this.pdfHeight = this.pdf.internal.pageSize.getHeight()
    this.margin = 15
    this.yOffset = this.margin
    this.listIndent = 1
    this.abortSignal = abortSignal
  }

  async generatePDF() {
    if (this.abortSignal.aborted) {
      throw new DOMException('Operation aborted', 'AbortError')
    }

    const chatElement = document.getElementById('chat-log')
    if (!chatElement) {
      throw new Error('Chat log element not found')
    }

    const messageGroups = chatElement.children
    for (let i = 0; i < messageGroups.length - 1; i++) {
      if (this.abortSignal.aborted) {
        throw new DOMException('Operation aborted', 'AbortError')
      }
      const group = messageGroups[i]
      await this.processMessageGroup(group, i)

      if (this.abortSignal.aborted) {
        throw new DOMException('Operation aborted', 'AbortError')
      }
    }
    this.savePDF()
  }

  async processMessageGroup(group, index) {
    if (this.abortSignal.aborted) {
      throw new DOMException('Operation aborted', 'AbortError')
    }
    const messageElements = group.querySelectorAll('.message__text')
    const questionElement = messageElements[0]
    const answerElement = messageElements[1]

    if (questionElement) {
      if (index > 0) this.addNewPage()
      try {
        await this.processQuestionElement(questionElement, index)
      } catch (error) {
        console.error(error)
        throw new Error('Error processing question element')
      }
    } else {
      console.warn('No question element found')
    }

    if (this.yOffset > this.pdfHeight - 4 * this.margin) this.addNewPage()

    if (answerElement) {
      try {
        await this.processAnswerElement(answerElement)
      } catch (error) {
        console.error(error)
        throw new Error('Error processing answer element')
      }
    } else {
      console.warn('No answer element found')
    }

    const sourcesElement = group.querySelector('.message__sources')

    if (sourcesElement) {
      try {
        this.addHyperlinksToPDF(sourcesElement)
      } catch (error) {
        console.error(error)
        throw new Error('Error processing sources element')
      }
    }

    this.yOffset += 10 // Adjust yOffset to account for new content
  }

  async processQuestionElement(element, sourceIndex = 0) {
    this.addTextToPDF(`${sourceIndex + 1}. ${element.textContent}`, 20, true)
  }

  async processAnswerElement(element) {
    const hasMathContent = element.querySelector('.katex')

    if (hasMathContent) {
      await this.addComplexContentToPDF(element)
      return
    }
    await this.processComplexElement(element)
  }

  async processComplexElement(element) {
    const treeWalker = document.createTreeWalker(element, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, null, false)

    let currentNode
    while ((currentNode = treeWalker.nextNode())) {
      try {
        await this.processNode(currentNode, treeWalker, element)
      } catch (error) {
        console.error(error)
        throw new Error('Error processing element')
      }
    }
  }

  async processNode(node, treeWalker, root) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const elementHandlers = {
        'text-pretty': async (el) => {
          await this.addComplexContentToPDF(el)
          this.skipToNextSibling(treeWalker, el, root)
        },
        H1: (el) => this.addTextToPDF(el.innerText, 16, true),
        H2: (el) => this.addTextToPDF(el.innerText, 15, true),
        H3: (el) => this.addTextToPDF(el.innerText, 14, true),
        H4: (el) => this.addTextToPDF(el.innerText, 12, true),
        H5: (el) => this.addTextToPDF(el.innerText, 12, true),
        H6: (el) => this.addTextToPDF(el.innerText, 12, true),
        OL: (el) => {
          this.processListElement(el)
          this.skipToNextSibling(treeWalker, el, root)
        },
        UL: (el) => {
          this.processListElement(el)
          this.skipToNextSibling(treeWalker, el, root)
        },
        P: (el) => {
          if (!this.isWithinList(el)) {
            // Skip paragraphs within lists, avoid duplicate text
            this.addTextToPDF(el.innerText)
          }
        },
        CODE: (el) => {
          if (!this.isCodeWithinParagraph(el)) {
            // Skip code blocks within paragraphs, avoid duplicate text
            this.addTextToPDF(el.innerText, 10, false, 5)
          }
        },
        TABLE: (el) => {
          this.addTableToPDF(el)
          this.skipToNextSibling(treeWalker, el, root)
        },
      }

      if (node.classList.contains('text-pretty') && !node.querySelector('table')) {
        await elementHandlers['text-pretty'](node)
      } else {
        const handler = elementHandlers[node.nodeName]
        if (handler) {
          await handler(node)
        }
      }
    }
  }

  addNewPage() {
    this.pdf.addPage()
    this.yOffset = this.margin
  }

  savePDF() {
    const downloadTime = new Date()
    const formattedDownloadTime = downloadTime.toISOString().replace(/[:.]/g, '-').replace('T', '_').slice(0, -5)
    const fileName = `chat-history-${formattedDownloadTime}.pdf`
    this.pdf.save(fileName)
  }

  async addComplexContentToPDF(element) {
    const canvas = await html2canvas(element, {
      scale: 3,
      logging: false,
      useCORS: true,
      height: element.scrollHeight + 25,
    })
    const imgData = canvas.toDataURL('image/png')
    const aspectRatio = canvas.width / canvas.height
    const imgWidth = this.pdfWidth - 3 * this.margin
    const imgHeight = imgWidth / aspectRatio

    if (this.yOffset + imgHeight > this.pdfHeight - 2 * this.margin) this.addNewPage()

    this.pdf.addImage(imgData, 'PNG', this.margin, this.yOffset + 5, imgWidth, imgHeight)
    this.yOffset += imgHeight + 10
  }

  addTextToPDF(text, fontSize = 12, isBold = false, indent = 0) {
    this.pdf.setFontSize(fontSize)
    this.pdf.setFont('helvetica', isBold ? 'bold' : 'normal')

    const startX = this.margin + indent
    const maxWidth = this.pdfWidth - this.margin - startX

    const textLines = this.pdf.splitTextToSize(text, maxWidth)

    textLines.forEach((line) => {
      if (this.yOffset > this.pdfHeight - 2 * this.margin) {
        this.addNewPage()
      }
      this.pdf.text(line, startX, this.yOffset)
      this.yOffset += fontSize * 0.3527 // Convert font size to mm (1pt = 0.3527mm)
    })

    this.yOffset += 3 // Add space after paragraph
  }

  addTableToPDF(tableElement) {
    const headers = Array.from(tableElement.querySelectorAll('th')).map((th) => th.textContent.trim())
    const data = Array.from(tableElement.querySelectorAll('tr'))
      .slice(headers.length > 0 ? 1 : 0)
      .map((tr) => {
        return Array.from(tr.querySelectorAll('td, th')).map((td) => td.textContent.trim())
      })

    if (data.length === 0 && headers.length === 0) return

    this.pdf.autoTable({
      head: headers.length > 0 ? [headers] : [],
      body: data,
      startY: this.yOffset,
      theme: 'grid',
      styles: { fontSize: 10, lineColor: [0, 0, 0], lineWidth: 0.1, fontFamily: 'helvetica' },
      headStyles: {
        fillColor: null,
        textColor: [0, 0, 0],
        fontStyle: 'bold',
        lineColor: [0, 0, 0],
      },

      margin: { left: this.margin, right: this.margin },
      didDrawPage: (data) => {
        this.yOffset = data.cursor.y + 10
      },
    })

    this.yOffset = this.pdf.lastAutoTable.finalY + 10
  }

  addHyperlinksToPDF(sourcesElement) {
    const links = sourcesElement.querySelectorAll('a')
    if (links.length > 0) {
      this.addTextToPDF('Sources:', 10, true)
      links.forEach((link, index) => {
        const linkText = `${index + 1}. ${link.innerText}`
        const linkUrl = link.href
        if (this.yOffset > this.pdfHeight - 2 * this.margin) this.addNewPage()
        this.pdf.setTextColor(0, 0, 255)
        this.pdf.textWithLink(linkText, this.margin, this.yOffset, { url: linkUrl })
        this.pdf.setTextColor(0)
        this.yOffset += 5
      })
      this.yOffset += 5
    }
  }

  processListElement(listElement, level = 0) {
    const listItems = listElement.children
    const isOrdered = listElement.nodeName === 'OL'
    let counter = isOrdered ? parseInt(listElement.getAttribute('start')) || 1 : null

    for (const item of listItems) {
      if (item.nodeName === 'LI') {
        let bulletPoint
        if (isOrdered) {
          bulletPoint = `${counter}. `
          counter++
        } else {
          bulletPoint = '• '
        }

        const indentation = ' '.repeat(level * 4)
        const text = this.getListItemText(item)
        this.addTextToPDF(`${indentation}${bulletPoint}${text}`, 12, false, this.listIndent * (level + 1))

        // Process nested lists
        const nestedList = item.querySelector('ul, ol')
        if (nestedList) {
          this.processListElement(nestedList, level + 1)
        }
      }
    }
  }

  getListItemText(listItem) {
    let text = ''
    for (const node of listItem.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.textContent.trim() + ' '
      } else if (node.nodeName === 'P') {
        text += node.innerText.trim() + ' '
      } else if (node.nodeName !== 'UL' && node.nodeName !== 'OL') {
        text += node.innerText.trim() + ' '
      }
    }
    return text.trim()
  }

  skipToNextSibling(treeWalker, node, root) {
    while (node && node !== root && !node.nextSibling) {
      node = node.parentNode
    }
    if (node && node !== root && node.nextSibling) {
      treeWalker.currentNode = node.nextSibling
    }
  }

  isWithinList(node) {
    let parent = node.parentElement
    while (parent) {
      if (parent.nodeName === 'UL' || parent.nodeName === 'OL' || parent.nodeName === 'LI') {
        return true
      }

      parent = parent.parentElement
    }
    return false
  }

  isCodeWithinParagraph(node) {
    let parent = node.parentElement
    while (parent) {
      if (parent.nodeName === 'P') {
        return true
      }

      parent = parent.parentElement
    }
    return false
  }
}

const ChatDownload = ({ closeButtonStyle, historyOpen, isStreaming }) => {
  const context = useContext(ViewContext)
  const handleDownload = async () => {
    const abortController = new AbortController()

    try {
      const pdfGenerator = new PDFGenerator(abortController.signal)
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          abortController.abort()
          reject(new Error('PDF generation timed out'))
        }, 30000) // 30 seconds
      })
      await Promise.race([pdfGenerator.generatePDF(), timeoutPromise])

      context.notification.show('Chat downloaded successfully', 'success', true)
    } catch (err) {
      if (err.message === 'PDF generation timed out' || err.name === 'AbortError') {
        context.notification.show('PDF generation timeout', 'error', false)
      } else {
        context.notification.show('Sorry, an error occurred when downloading the chat', 'error', false)
      }
    }
  }
  return (
    <DownloadButton
      closeButtonStyle={closeButtonStyle}
      historyOpen={historyOpen}
      isStreaming={isStreaming}
      handleDownload={handleDownload}
    />
  )
}

export default ChatDownload
