// import React from 'react'
import ViewDocument from 'views/searchAI/viewDocument'
import SearchResults from 'views/searchAI/searchResults'
import SearchTool from 'views/searchAI/searchTool'
// const ViewDocument = React.lazy(() => import('views/searchAI/assets/viewDocument'))
// const SearchResults = React.lazy(() => import('views/searchAI/searchResults'))
// const SearchTool = React.lazy(() => import('views/searchAI/searchTool'))

const Routes =
  // TODO: remove this when we have a proper database search page
  process.env.NODE_ENV === 'production'
    ? []
    : [
        {
          path: '/seafarer',
          view: SearchTool,
          layout: 'app',
          permission: 'user',
          title: 'Seafarer',
        },
        {
          path: '/seafarer/results',
          view: SearchResults,
          layout: 'app',
          permission: 'user',
          title: 'Search Results',
        },
        {
          path: 'document/:namespace/:documentId',
          view: ViewDocument,
          layout: 'app',
          permission: 'user',
          title: 'Document',
        },
      ]
export default Routes
